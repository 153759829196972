/* Custom styles for the navbar */
.navbar {
  width: 250px; /* Adjust the width of the navbar */
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important; /* Align content at the top */
  padding: 20px;
  background-color: #343a40; /* Dark background */
}

/* Logo styling */
.navbar-logo {
  width: 200px; /* Adjust logo size */
  margin-bottom: 20px;
}

/* Navigation links styling */
.navbar-nav {
  width: 100%; /* Full width for nav items */
}

.nav-link {
  display: flex;
  padding: 10px 20px;
  width: 100%;
  text-align: center;
  color: #ffffff; /* Text color */
}

.nav-link:hover {
  background-color: #495057; /* Hover effect */
  color: #ffffff;
}

/* Styles for the main content */
.main-content {
  display: flex;
  
}



/* Button styles */
.btn-hover {
  display: flex; /* Add Flexbox */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  font-size: 18px; /* Increase font size */
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 65px; /* Increase height */
  width: 220px; /* Increase width */
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  transition: all .4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  transition: all .4s ease-in-out;
}



/* Button styles */
.btn-hover {
  display: flex; /* Add Flexbox */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  font-size: 18px; /* Increase font size */
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}




/* Define styles for compact view */
.table.compact th, .table.compact td {
  padding: 0.1rem; /* Reduce padding for compact view */
  font-size: 0.75rem; /* Reduce font size for compact view */
}





/* Button styles */
.btn-hover {
  display: flex; /* Add Flexbox */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  font-size: 18px; /* Increase font size */
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 65px; /* Increase height */
  width: 220px; /* Increase width */
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  transition: all .4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  transition: all .4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-attach {
  background-image: linear-gradient(to right, #757575, #9e9e9e, #bdbdbd, #e0e0e0);
  box-shadow: 0 4px 15px 0 rgba(158, 158, 158, 0.75);
}

.btn-send {
  background-image: linear-gradient(to right, #25aae1, #40e495, #30dd8a, #2bb673);
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
}

.icon-container {
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50%;
  transition: all .4s ease-in-out;
}

.icon-container:hover {
  background-position: 100% 0;
  transition: all .4s ease-in-out;
}

.icon-container:focus {
  outline: none;
}

.icon-download {
  background-image: linear-gradient(to right, #0ba360, #3cba92, #30dd8a, #2bb673);
  box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
}

.icon-trash {
  background-image: linear-gradient(to right, #eb3941, #f15e64, #e14e53, #e2373f);
  box-shadow: 0 5px 15px rgba(242, 97, 103, .4);
}

.textarea-large {
  width: 100%;
  height: 200px;
  font-size: 16px;
  padding: 10px;
}

.btn-logout {
  background-image: linear-gradient(to right, #ff5f6d, #ffc371);
  box-shadow: 0 4px 15px 0 rgba(255, 95, 109, 0.75);
}


.btn-login {
  background-image: linear-gradient(to right, #36d1dc, #5b86e5);
  box-shadow: 0 4px 15px 0 rgba(54, 209, 220, 0.75);
}

.login-title {
  margin-top: 30px;
  margin-bottom: 10px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}

.login-version {
  margin-bottom: 50px;
  text-size-adjust: 100%;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

/* Footer styling */
.footer {
  width: 100%;
  text-align: center;
  padding: 1em;
  background-color: #343a40; /* Same dark background as navbar */
  color: #ffffff; /* White text color */
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; /* Same font as navbar */
}
