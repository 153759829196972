.App {
  text-align: center;
}


/* Bootstrap*/
@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css');


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* General styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

body {
  background-color: #f4f4f9;
  color: #333;
  line-height: 1.6;
}

/* Container styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Heading styles */
h1, h2, h3 {
  margin-bottom: 20px;
  color: #444;
}

/* Button styles */
button {
  background-color: #6200ea;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #3700b3;
}

/* Input styles */
input[type="text"],
input[type="password"],
textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0 20px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Navbar styles */
.navbar {
  background-color: #6200ea;
  padding: 10px 20px;
  color: white;
}

.navbar a {
  color: white;
  text-decoration: none;
  margin-right: 20px;
}

.navbar a:hover {
  text-decoration: underline;
}

/* Form container styles */
.form-container {
  background: white;
  padding: 40px;
  margin: 20px 0;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


/* Bootstrap overrides and custom styles */

.container {
  padding: 20px;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.table {
  width: 100%;
  margin: 20px 0;
  font-size: 18px;
}

.table th, .table td {
  padding: 12px 15px;
}

.btn {
  margin: 5px;
}

thead th {
  background-color: #007bff;
  color: white;
}




.container {
  padding: 20px;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.form-control {
  margin-bottom: 10px;
}

.form-check-label {
  margin-left: 10px;
}

.btn {
  margin-top: 15px;
}